import React from 'react'
//import {Link} from 'gatsby'
import BlogSidebar from './BlogSidebar'
import { useIntl } from "react-intl"
import { LocalizedLink as Link } from "gatsby-theme-i18n"


const BlogDetailsContent = (blog: any) => {
    const intl = useIntl()

    return (
        <section className="blog-details-area ptb-100">
            <div className="container">
                <div className="row">
                    {
                        
                    }
                    <div className="col-lg-8 col-md-12">
                        <div className="blog-details-desc">
                            <div className="article-image">
                                <img src={"http://162.19.67.2:1337" + blog?.blog?.attributes?.image?.data?.attributes?.url} alt="bb" />
                            </div>

                            <div className="article-content" >
                                <div className="entry-meta">
                                    <ul  style={intl.locale === 'ar' ? { textAlign: 'right', direction:'ltr'} : { textAlign: 'left', direction:'rtl' }}>
                                        <li>
                                            <i className='bx bx-folder-open'></i>
                                            <span>{intl.formatMessage({ id: "blogcategory" })}{" "}</span>
                                            <Link language={intl.locale} to="/blog">
                                                {blog?.blog?.attributes?.category.data.attributes.name}
                                            </Link>
                                        </li>
                                        <li>
                                            <i className='bx bx-group'></i>
                                            <span>{intl.formatMessage({ id: "blogviews" })}{" "}</span>
                                            <Link language={intl.locale} to="#">
                                               127
                                            </Link>
                                        </li>
                                        <li>
                                            <i className='bx bx-calendar'></i>
                                            <span>{intl.formatMessage({ id: "bloglastupdated" })}{" "}</span>
                                            <Link language={intl.locale} to="#">
                                            {new Date(blog?.blog?.attributes?.updatedAt).toLocaleDateString()}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <h3 style={intl.locale === 'ar' ? { textAlign: 'right'} : { textAlign: 'left'}}>{blog?.blog?.attributes?.title}</h3>

                                <p style={intl.locale === 'ar' ? { textAlign: 'right'} : { textAlign: 'left'}}>
                                    
                                    {blog?.blog?.attributes?.content}
                                
                                </p>

                                <blockquote>
                                    <p>{blog?.blog?.attributes?.caption}</p>
                                    <cite>{blog?.blog?.attributes?.author.data.attributes.firstname}</cite>
                                </blockquote>
                            </div>
                        </div>
                    </div>

                    {/*<div className="col-lg-4 col-md-12">
                        <BlogSidebar blog={blog} />
                </div>*/}
                </div>
            </div>
        </section>
    )
}

export default BlogDetailsContent
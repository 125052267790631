import React, { useState } from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import BlogDetailsContent from "../components/BlogContent/BlogDetailsContent"
import client from "../utils/apollo-client"
import { gql, useMutation, useQuery } from "@apollo/client"
import { useIntl } from "react-intl"

const GET_BLOG = gql`
  query GetBlog($id: ID!) {
    blog(id: $id) {
      data {
        id
        attributes {
          title
          slug
          caption
          content
          publishedAt
          updatedAt
          date
          image {
            data {
              attributes {
                url
              }
            }
          }
          author {
            data {
              attributes {
                firstname
                lastname
                email
                title
                phonenumber
                linkedinurl
                twitterurl
              }
            }
          }
          category {
            data {
              attributes {
                code
                name
              }
            }
          }
        }
      }
    }
  }
`
//generate query for courses
const BlogDetails = (location: any) => {
    const intl = useIntl()

  const { loading, error, data } = useQuery(GET_BLOG, {
    client: client,
    variables: { id: location?.location?.state?.id },
  })

  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle={intl.formatMessage({ id: "blogdetails" })}
        homePageText={intl.formatMessage({ id: "home" })}
        homePageUrl="/"
        activePageText={intl.formatMessage({ id: "blogdetails" })}
      />
      {loading && <p>Loading ...</p>}
      {!loading && <BlogDetailsContent blog={data?.blog?.data} />}

      <Footer />
    </Layout>
  )
}

export default BlogDetails
